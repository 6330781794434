<template>
  <div class="login">
    <div class="login-box">
      <p style="color: #2961ff; font-size: 1.166667rem">欢迎登录</p>
      <p style="color: #ffffff; font-size: 1rem; margin-top: 0.4rem">
        智慧康养信息平台
      </p>
      <div class="userInput">
        <img
          src="../../image/login/user.png"
          alt=""
          style="margin-right: 0.222rem"
        />
        <input
          type="text"
          v-model.trim="loginMessage.username"
          placeholder="请输入登录账号"
          style="background: transparent; border: none; height: 0.916rem"
        />
      </div>
      <div class="userInput">
        <img
          src="../../image/login/password.png"
          alt=""
          style="margin-right: 0.222rem"
        />
        <input
          type="password"
          v-model.trim="loginMessage.password"
          @keyup.enter="handleSubmit"
          v-if="show"
          placeholder="请输入登录密码"
        />
        <input
          type="text"
          v-model.trim="loginMessage.password"
          @keyup.enter="handleSubmit"
          v-if="!show"
          placeholder="请输入登录密码"
        />
        <img
          src="../../image/login/hidden.png"
          alt=""
          @click="show = !show"
          v-if="show"
          style="position: absolute; right: 1rem; cursor: pointer"
        />
        <img
          src="../../image/login/show.png"
          alt=""
          @click="show = !show"
          v-if="!show"
          style="position: absolute; right: 1rem; cursor: pointer"
        />
      </div>
      <div>
        <el-button
          :disabled="loginMessage.username == '' || loginMessage.password == ''"
          @click.native.prevent="handleSubmit"
          >立即登录</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginMessage: {
        username: '',
        password: '',
      },
      show: true,
    }
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch('user/login', this.loginMessage).then((res) => {
        if (res.data.code == 0) {
          this.$router.push('/')
        } else {
          // 提示错误
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  width: 100%;
  background: url('../../image/login/background.png') no-repeat center center;
  background-size: cover;
  position: relative;
  .login-box {
    position: absolute;
    top: 14rem;
    left: 58rem;
  }
  .userInput {
    position: relative;
    width: 12.833rem;
    height: 1.3333rem;
    background: #33343b;
    border-radius: 2px;
    border: 1px solid #42444e;
    display: flex;
    align-items: center;
    padding-left: 0.3333rem;
    margin-top: 1rem;
    box-sizing: border-box;
  }

  input {
    background: transparent;
    border: none;
    height: 0.916rem;
    width: 11rem;
    color: #fff;
  }
}
input[type='text']:focus {
  outline: none;
}

input[type='password']:focus {
  outline: none;
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  // 背景颜色
  background-color: transparent !important;
  //设置input输入框的背景颜色为透明色
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.el-button {
  width: 12.833rem;
  margin-top: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.is-disabled {
  background: rgba(41, 97, 255, 0.1) !important;
  border: none;
  color: rgba(255, 255, 255, 0.27) !important;
  &:hover {
    background: rgba(41, 97, 255, 0.1) !important;
  }
}

.el-button {
  background: #2961ff;
  color: #fff;
  border: none;
  &:hover {
    color: #fff;
  }
}
</style>
